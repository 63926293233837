/**
 * @module Constants
 */

// Environment
export const ENVIRONMENT = {
  dev: process.env.NODE_ENV === 'development',
  development: process.env.NODE_ENV === 'development',
  prod: process.env.NODE_ENV === 'production',
  production: process.env.NODE_ENV === 'production',
  review: process.env.NODE_ENV === 'review',
  staging: process.env.NODE_ENV === 'staging',
  test:
    process.env.NODE_ENV === 'test' ||
    (process.env.IS_JEST_ENV && process.env.IS_JEST_ENV.toString() === 'true'),
};

// Log Configuration
export const LOG_CONFIG = {
  colorize:
    process.env.LOG_OPTIONS_COLORIZE &&
    process.env.LOG_OPTIONS_COLORIZE.toString() === 'true',
  includeTimestamp:
    process.env.LOG_OPTIONS_INCLUDE_TIMESTAMP &&
    process.env.LOG_OPTIONS_INCLUDE_TIMESTAMP.toString() === 'true',
  label: process.env.LOG_OPTIONS_LABEL || 'Finds',
  printOutput:
    (process.env.LOG_OPTIONS_PRINT_OUTPUT &&
      process.env.LOG_OPTIONS_PRINT_OUTPUT.toString() === 'true') ||
    !ENVIRONMENT.production,
  showIcons:
    process.env.LOG_OPTIONS_SHOW_ICONS &&
    process.env.LOG_OPTIONS_SHOW_ICONS.toString() === 'true',
};

// -----------------------------------------------------------------------------
//  Start: Magnolia Helpers
// -----------------------------------------------------------------------------

export const MGNL_ENV_VARS = {
  REACT_APP_CMS: process.env.REACT_APP_CMS,
  REACT_APP_MGNL_API_ARTICLES_FINDS:
    process.env.REACT_APP_MGNL_API_ARTICLES_FINDS,
  REACT_APP_MGNL_API_CONTENT_TYPE: process.env.REACT_APP_MGNL_API_CONTENT_TYPE,
  REACT_APP_MGNL_API_NAV: process.env.REACT_APP_MGNL_API_NAV,
  REACT_APP_MGNL_API_NAV_FINDS: process.env.REACT_APP_MGNL_API_NAV_FINDS,
  REACT_APP_MGNL_API_PAGES: process.env.REACT_APP_MGNL_API_PAGES,
  REACT_APP_MGNL_API_PAGES_FINDS: process.env.REACT_APP_MGNL_API_PAGES_FINDS,
  REACT_APP_MGNL_API_TEMPLATES: process.env.REACT_APP_MGNL_API_TEMPLATES,
  REACT_APP_MGNL_APP_BASE: process.env.REACT_APP_MGNL_APP_BASE,
  REACT_APP_MGNL_AUTHOR: process.env.REACT_APP_MGNL_AUTHOR,
  REACT_APP_MGNL_BASE_AUTHOR: process.env.REACT_APP_MGNL_BASE_AUTHOR,
  REACT_APP_MGNL_BASE_PUBLIC: process.env.REACT_APP_MGNL_BASE_PUBLIC,
  REACT_APP_MGNL_DAM_RAW: process.env.REACT_APP_MGNL_DAM_RAW,
  REACT_APP_MGNL_HOST: process.env.REACT_APP_MGNL_HOST,
  REACT_APP_MGNL_IS_PREVIEW: process.env.REACT_APP_MGNL_IS_PREVIEW,
  REACT_APP_MGNL_LANGUAGES: process.env.REACT_APP_MGNL_LANGUAGES,
  REACT_APP_MGNL_STATIC: process.env.REACT_APP_MGNL_STATIC,
};

export const ENDPOINT_WORKSPACE_MAP = {
  '/.rest/delivery/careersMenu': 'careersMenu',
  '/.rest/delivery/distributionPlatform': 'distributionPlatform',
  '/.rest/delivery/featuredNextStepCard': 'featuredNextStepCard',
  '/.rest/delivery/finds/footer': 'finds-footer',
  '/.rest/delivery/finds/journey': 'finds-journey',
  '/.rest/delivery/finds/menu': 'finds-menu',
  '/.rest/delivery/finds/share': 'finds-share',
  '/.rest/delivery/finds-articles/v1': 'finds-articles',
  '/.rest/delivery/finds-pagenav/v1': 'finds-website',
  '/.rest/delivery/finds-pages/v1': 'finds-website',
  '/.rest/delivery/footer': 'footer',
  '/.rest/delivery/globalLocationBanner': 'globalLocationBanner',
  '/.rest/delivery/homepageHero': 'homepageHero',
  '/.rest/delivery/labeldictionary': 'labeldictionary',
  '/.rest/delivery/location': 'location',
  '/.rest/delivery/mediaPerson': 'mediaPerson',
  '/.rest/delivery/mediav2': 'media_v2',
  '/.rest/delivery/mediaV2': 'media_v2',
  '/.rest/delivery/menu': 'menu',
  '/.rest/delivery/messageNotes': 'messageNotes',
  '/.rest/delivery/modal': 'modal',
  '/.rest/delivery/nextStepCard': 'nextStepCard',
  '/.rest/delivery/pagenav/v1': 'website',
  '/.rest/delivery/pages/v1': 'website',
  '/.rest/delivery/servingOpportunities': 'servingOpportunities',
  '/.rest/delivery/specialEvent': 'specialEvent',
  '/.rest/delivery/tag': 'tag',
  '/.rest/delivery/talkItOver': 'talkItOver',
  '/.rest/delivery/tasks': 'tasks',
  '/.rest/delivery/v1/collections': 'media_v2',
  '/.rest/delivery/v1/items': 'media_v2',
  '/.rest/mediaV2': 'media_v2',
  mediaV2: 'media_v2',
  mediav2: 'media_v2',
};

// -----------------------------------------------------------------------------
//  End: Magnolia Helpers
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
//  Start: Analytics Tracking
// -----------------------------------------------------------------------------

// Categories
export const CATEGORIES = {
  compositeButtonGroup: 'Composite Button Group',
  header: 'Header',
};

// Actions
export const ACTIONS = {
  changed: 'Changed',
  clicked: 'Clicked',
  closed: 'Closed',
  collapsed: 'Collapsed',
  expanded: 'Expanded',
  formSubmit: 'Form Submit',
  opened: 'Opened',
  paused: 'Paused',
  played: 'Played',
  selected: 'Selected',
  stopped: 'Stopped',
  tapped: 'Tapped',
  toggledOff: 'Toggled Off',
  toggledOn: 'Toggled On',
};

// Events
export const EVENTS = {
  buttonAction: 'Button Action',
  formAction: 'Form Action',
  linkClick: 'Link Click',
  mediaPlayed: 'Media Played',
  menuItemSelect: 'Menu Item Select',
  searchStarted: 'Search Started',
  searchSubmitted: 'Search Submitted',
};

// Screens
export const SCREENS = {};

// Parameters
export const PARAMETERS = {
  mediaTypes: {
    video: 'video',
  },
};

// -----------------------------------------------------------------------------
//  End: Analytics Tracking
// -----------------------------------------------------------------------------

// RegExp
export const REGEXP = {
  multiSlash: /\/+/g,
  multiSlashSaveUrlProtocol: /(?<!:)\/+/g,
};

export const SOCIAL_MEDIA_TYPES = {
  facebook: 'Facebook',
  instagram: 'Instagram',
  linkedin: 'LinkedIn',
  pinterest: 'Pinterest',
  tiktok: 'TikTok',
  twitter: 'Twitter',
  youtube: 'YouTube',
};

// Status Types
export const STATUS_TYPES = {
  ERROR: 'error',
  IDLE: 'idle',
  INFO: 'info',
  PENDING: 'pending',
  REJECTED: 'rejected',
  RESOLVED: 'resolved',
  SUCCESS: 'success',
  WARNING: 'warning',
};

// Window Breakpoints
export const BREAKPOINTS = {
  large: 1024,
  medium: 768,
  small: 640,
  xLarge: 1200,
};

export const MEDIA_TRACKING = {
  segment: {
    events: {
      timeTriggers: {
        3: '3',
        10: '10',
        30: '30',
        60: '60',
        start: '0',
      },
    },
  },
};

// Magnolia Delivery API
export const WEB_DISTRIBUTION_PLATFORM_UUID =
  '49b543e6-bcef-4242-b273-363e87d5b0a3';

// Algolia Search
export const ALGOLIA = {
  apiKey: '81d4ef42f6fb0cc670eeb4bbb25bbce4',
  appId: 'YKE366P1JO',
};

// Hubspot
export const HUBSPOT = {
  formIds: {
    getUpdates: '643f0cf1-8238-4cf1-8706-f92860dfa0ca',
    podcastCollectionContentForm: '76a971af-d87a-4f27-b0a2-bd42f650cee1',
  },
};

// Themes
export const THEMES = {
  contentOnly: 'content_only',
};

export const COLOR_THEMES = {
  'dark-mode': 'dark-mode',
  'light-mode': 'light-mode',
};

export const FINISH_JOURNEY_URL = '/finish-journey';

export const ICON_TYPES = Object.freeze({
  audio: 'audio',
  text: 'text',
  video: 'video',
});

export const ZERO_RESULTS_TEXT =
  'Sorry we couldn’t find what you’re looking for. You can either update your search or explore some of these helpful resources below.';

// Algolia indexes, replicas and sort items;

export const ARTICLES_INDEX = process.env.FINDS_ALGOLIA_INDEX_ARTICLES || '';
const OLD_NEW_ARTICLES_INDEX =
  process.env.FINDS_ALGOLIA_INDEX_OLD_NEW_ARTICLES || '';
const A_TO_Z_ARTICLES_INDEX =
  process.env.FINDS_ALGOLIA_INDEX_A_TO_Z_ARTICLES || '';
const Z_TO_A_ARTICLES_INDEX =
  process.env.FINDS_ALGOLIA_INDEX_Z_TO_A_ARTICLES || '';

export const JOURNEYS_INDEX = process.env.FINDS_ALGOLIA_INDEX_JOURNEYS || '';
const OLD_NEW_JOURNEYS_INDEX =
  process.env.FINDS_ALGOLIA_INDEX_OLD_NEW_JOURNEYS || '';
const A_TO_Z_JOURNEYS_INDEX =
  process.env.FINDS_ALGOLIA_INDEX_A_TO_Z_JOURNEYS || '';
const Z_TO_A_JOURNEYS_INDEX =
  process.env.FINDS_ALGOLIA_INDEX_Z_TO_A_JOURNEYS || '';

export const ARTICLES_SORT_ITEMS = [
  { label: 'Newest to Oldest', value: ARTICLES_INDEX },
  { label: 'Oldest to Newest', value: OLD_NEW_ARTICLES_INDEX },
  { label: 'A to Z', value: A_TO_Z_ARTICLES_INDEX },
  { label: 'Z to A', value: Z_TO_A_ARTICLES_INDEX },
];

export const JOURNEYS_SORT_ITEMS = [
  { label: 'Newest to Oldest', value: JOURNEYS_INDEX },
  { label: 'Oldest to Newest', value: OLD_NEW_JOURNEYS_INDEX },
  { label: 'A to Z', value: A_TO_Z_JOURNEYS_INDEX },
  { label: 'Z to A', value: Z_TO_A_JOURNEYS_INDEX },
];

// Fallback Image
export const FALLBACK_IMAGE = process.env.FALLBACK_ARTICLE_IMAGE || '';

// Hyphenated Tags
export const HYPHENATED_TAGS = ['well-being'];

// Video Orientation
export const VIDEO_PORTRAIT_WIDTH = 33;
export const VIDEO_ORIENTATION = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
};
